import React from 'react'
import loadable from '@loadable/component'

const ComponentList = {
  Craft_dynamicContent_hero_BlockType: loadable(() => import(`./Modules/Hero`)),
  Craft_dynamicContent_heroBlog_BlockType: loadable(() =>
    import(`./Modules/HeroBlog`)
  ),
  Craft_dynamicContent_textImage_BlockType: loadable(() =>
    import(`./Modules/TextImage`)
  ),
  Craft_dynamicContent_textImageSquares_BlockType: loadable(() =>
    import(`./Modules/TextImageSquares`)
  ),
  Craft_dynamicContent_oneCol_BlockType: loadable(() =>
    import(`./Modules/OneCol`)
  ),
  Craft_dynamicContent_twoCols_BlockType: loadable(() =>
    import(`./Modules/TwoCols`)
  ),
  Craft_dynamicContent_threeCols_BlockType: loadable(() =>
    import(`./Modules/ThreeCols`)
  ),
  Craft_dynamicContent_quote_BlockType: loadable(() =>
    import(`./Modules/Quote`)
  ),
  Craft_dynamicContent_staticImageVideo_BlockType: loadable(() =>
    import(`./Modules/StaticImageVideo`)
  ),
  Craft_dynamicContent_templateElement_BlockType: loadable(() =>
    import(`./Modules/TemplateElement`)
  ),
  Craft_dynamicContent_forhandlere_BlockType: loadable(() =>
    import(`./Modules/Forhandlere`)
  ),
  Craft_dynamicContent_divide_BlockType: loadable(() =>
    import(`./Modules/Divide`)
  ),
  Craft_dynamicContent_contacts_BlockType: loadable(() =>
    import(`./Modules/Contacts`)
  ),
  Craft_dynamicContent_comparisonTable_BlockType: loadable(() =>
    import(`./Modules/ComparisonTable`)
  ),
  Craft_dynamicContent_vindusdesigner_BlockType: loadable(() =>
    import(`./Modules/Vindusdesigner`)
  ),
  Craft_dynamicContent_leveringstider_BlockType: loadable(() =>
    import(`./Modules/Leveringstider`)
  ),
  Craft_dynamicContent_forms_BlockType: loadable(() =>
    import(`./Modules/Forms`)
  ),
  Craft_dynamicContent_documents_BlockType: loadable(() =>
    import(`./Modules/Documents`)
  ),
  Craft_dynamicContent_energyCalculator_BlockType: loadable(() =>
    import(`./Modules/EnergyCalculator`)
  ),
  Craft_dynamicContent_faq_BlockType: loadable(() => import(`./Modules/Faq`)),
}

const DynamicComponent = ({
  contentTypes,
  contentTypesCat,
  inheritCategoryContent,
  slug,
  title,
  products,
  productSpecs,
  bild,
  productCarousel,
  defaultSpecs,
  relatedAssets,
  relatedOptions,
  relatedDesigns,
  externalLinks,
}) => {
  if (contentTypes === null) return <></>

  const contentTypesMerged = inheritCategoryContent
    ? [...contentTypes, ...(contentTypesCat || [])]
    : contentTypes

  return contentTypesMerged.map((item, index) => {
    const Component = ComponentList[item.__typename]

    // The server should always render the module so we get the static HTML.
    if (typeof window === 'undefined') {
      return renderModule(Component, index)
    }

    const wasUserPreviouslyOnSite = window.history.state
    const htmlEl = document.querySelector(
      `[data-module-index="${index.toString()}"]`
    )
    if (htmlEl && !wasUserPreviouslyOnSite) {
      return staticRenderModule(index, htmlEl)
    }

    const fallback = htmlEl ? staticRenderModule(index, htmlEl) : null
    return renderModule(Component, index, fallback)

    // Render the module with the HTML currently rendered from the static HTML file without importing the javascript.
    function staticRenderModule(index, htmlEl) {
      return (
        <section
          key={index.toString()}
          dangerouslySetInnerHTML={{ __html: htmlEl.innerHTML }}
        />
      )
    }

    // Render the module with all the javascript.
    function renderModule(Component, index, fallback = null) {
      if (Component === undefined) return <></>
      return (
        <Component
          key={index}
          content={item}
          slug={slug}
          bild={bild}
          title={title}
          products={products}
          productSpecs={productSpecs}
          productCarousel={productCarousel}
          defaultSpecs={defaultSpecs}
          fallback={fallback}
          relatedAssets={relatedAssets}
          relatedOptions={relatedOptions}
          relatedDesigns={relatedDesigns}
          externalLinks={externalLinks}
        />
      )
    }
  })
}

export default DynamicComponent
